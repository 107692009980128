import { render, staticRenderFns } from "./Church_Icon_Blue.vue?vue&type=template&id=13f1dd84&scoped=true&"
var script = {}
import style0 from "./Church_Icon_Blue.vue?vue&type=style&index=0&id=13f1dd84&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13f1dd84",
  null
  
)

export default component.exports