import { render, staticRenderFns } from "./Scripture_Distribution_Icon_Blue.vue?vue&type=template&id=37a7e82d&scoped=true&"
var script = {}
import style0 from "./Scripture_Distribution_Icon_Blue.vue?vue&type=style&index=0&id=37a7e82d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37a7e82d",
  null
  
)

export default component.exports